<template>
  <div class="new-polis panel is-primary">
    <h1 class="title is-6 panel-heading mb-0">Создание полиса для клиента</h1>
    <form class="box" @submit.prevent="submit">
      <div class="columns is-multiline">
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-fifth-fullhd"
        >
          <b-field
            label="Тип полиса"
            :type="{
              'is-danger': $v.type.$error || 'type' in serverValid,
              'is-success': !$v.type.$invalid && $v.type.$dirty,
            }"
            :message="{ 'Некорректно заполено поле': $v.type.$error }"
          >
            <b-select placeholder="Выберите тип полиса" expanded v-model="type">
              <option value="1">Страхование ОСАГО</option>
              <option value="2">Страхование КАСКО</option>
              <option value="3">Страхование Имущества</option>
              <option value="4">Страхование Жизни</option>
            </b-select>
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-fifth-fullhd"
        >
          <b-field
            label="Серия полиса"
            :type="{
              'is-danger': $v.series.$error || 'series' in serverValid,
              'is-success': !$v.series.$invalid && $v.series.$dirty,
            }"
            :message="{ 'Некорректно заполено поле': $v.series.$error }"
          >
            <b-input
              placeholder="Серия полиса"
              v-model.trim="series"
              @blur="
                onTouchField('series');
                removeKeyFromServerValid('series');
              "
            ></b-input>
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-fifth-fullhd"
        >
          <b-field
            label="Номер полиса"
            :type="{
              'is-danger': $v.number.$error || 'number' in serverValid,
              'is-success': !$v.number.$invalid && $v.number.$dirty,
            }"
            :message="{ 'Некорректно заполено поле': $v.number.$error }"
          >
            <b-input
              placeholder="Номер полиса"
              v-model.trim="number"
              @blur="
                onTouchField('number');
                removeKeyFromServerValid('number');
              "
            ></b-input>
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-fifth-fullhd"
        >
          <b-field
            label="Дата выдачи"
            :type="{
              'is-danger': $v.startDate.$error || 'startDate' in serverValid,
              'is-success': !$v.startDate.$invalid && $v.startDate.$dirty,
            }"
            :message="{
              'Некорректно указана дата': $v.startDate.$error,
            }"
          >
            <IMaskComponent
              placeholder="00.00.0000 00:00"
              inputmode="numeric"
              class="input"
              :class="{
                'is-danger': $v.startDate.$error || 'startDate' in serverValid,
                'is-success': !$v.startDate.$invalid && $v.startDate.$dirty,
              }"
              :blocks="startDateMaskBlocks"
              :mask="'D{.}M{.}Y h{:}m'"
              v-model.trim="startDate"
              @blur="
                onTouchField('startDate');
                removeKeyFromServerValid('startDate');
              "
            />
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-fifth-fullhd"
        >
          <b-field
            label="Дата окончания"
            :type="{
              'is-danger': $v.endDate.$error || 'endDate' in serverValid,
              'is-success': !$v.endDate.$invalid && $v.endDate.$dirty,
            }"
            :message="{
              'Некорректно указана дата': $v.endDate.$error,
            }"
          >
            <IMaskComponent
              placeholder="00.00.0000 00:00"
              inputmode="numeric"
              class="input"
              :class="{
                'is-danger': $v.endDate.$error || 'endDate' in serverValid,
                'is-success': !$v.endDate.$invalid && $v.endDate.$dirty,
              }"
              :blocks="endDateMaskBlocks"
              :mask="'D{.}M{.}Y h{:}m'"
              v-model.trim="endDate"
              @blur="
                onTouchField('endDate');
                removeKeyFromServerValid('endDate');
              "
            />
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
          v-if="showCarFields"
        >
          <b-field
            label="Марка авто"
            :type="{
              'is-danger': $v.marka.$error || 'marka' in serverValid,
              'is-success': !$v.marka.$invalid && $v.marka.$dirty,
            }"
            :message="{ 'Некорректно заполнено поле': $v.marka.$error }"
          >
            <b-autocomplete
              placeholder="Марка авто"
              :data="markaData"
              :keep-first="false"
              :open-on-focus="false"
              :clearable="true"
              :loading="isFetching"
              v-model.trim="marka"
              @typing="getDadata($event, 'marka')"
              @blur="
                onTouchField('marka');
                removeKeyFromServerValid('marka');
              "
            >
            </b-autocomplete>
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
          v-if="showCarFields"
        >
          <b-field
            label="Модель авто"
            :type="{
              'is-danger': $v.model.$error || 'model' in serverValid,
              'is-success': !$v.model.$invalid && $v.model.$dirty,
            }"
            :message="{ 'Некорректно заполено поле': $v.model.$error }"
          >
            <b-input
              placeholder="Модель авто"
              v-model.trim="model"
              @blur="
                onTouchField('model');
                removeKeyFromServerValid('model');
              "
            ></b-input>
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
          v-if="showCarFields"
        >
          <b-field
            label="Гос номер авто"
            :type="{
              'is-danger': $v.carNumber.$error || 'carNumber' in serverValid,
              'is-success': !$v.carNumber.$invalid && $v.carNumber.$dirty,
            }"
            :message="{
              'Гос номер должен состоять из кириллицы': !$v.carNumber.alphaNum,
              'Гос номер авто 8-9 символов': !$v.carNumber.minLength,
            }"
          >
            <b-input
              inputmode="numeric"
              placeholder="A000AA152"
              v-model.trim="carNumber"
              @blur="
                onTouchField('carNumber');
                removeKeyFromServerValid('carNumber');
              "
            ></b-input>
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd field-with-checkbox"
          v-if="showCarFields"
        >
          <b-field
            label="VIN код авто"
            :type="{
              'is-danger': $v.vin.$error || 'vin' in serverValid,
              'is-success': !$v.vin.$invalid && $v.vin.$dirty,
            }"
            :message="{ 'VIN код авто 17 символов': $v.vin.$error }"
          >
            <IMaskComponent
              placeholder="VIN"
              inputmode="numeric"
              class="input"
              :class="{
                'is-danger': $v.vin.$error || 'vin' in serverValid,
                'is-success': !$v.vin.$invalid && $v.vin.$dirty,
              }"
              :disabled="withoutVin"
              :mask="'*****************'"
              :prepare="(str) => str.toUpperCase()"
              v-model.trim="vin"
              @blur="onTouchField('vin')"
            />
          </b-field>
          <b-field class="checkbox-vin">
            <b-checkbox size="is-small" v-model="withoutVin"
              >VIN отсутствует</b-checkbox
            >
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field
            label="Стоимость полиса"
            :type="{
              'is-danger': $v.amount.$error || 'amount' in serverValid,
              'is-success': !$v.amount.$invalid && $v.amount.$dirty,
            }"
            :message="{ 'Некорректно заполено поле': $v.amount.$error }"
          >
            <!-- <b-input
              placeholder="Стоимость полиса"
              icon-right="ruble-sign"
              v-model="amount"
              @blur="
                onTouchField('amount');
                removeKeyFromServerValid('amount');
              "
            ></b-input> -->
            <IMaskComponent
              placeholder="Стоимость полиса"
              inputmode="numeric"
              class="input"
              :class="{
                'is-danger': $v.amount.$error || 'amount' in serverValid,
                'is-success': !$v.amount.$invalid && $v.amount.$dirty,
              }"
              :mask="Number"
              :scale="2"
              :padFractionalZeros="true"
              :normalizeZeros="true"
              :radix="'.'"
              v-model.trim="amount"
              @blur="
                onTouchField('amount');
                removeKeyFromServerValid('amount');
              "
            />
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field
            label="Комиссионное вознаграждение"
            :type="{
              'is-danger': $v.kv.$error || 'kv' in serverValid,
              'is-success': !$v.kv.$invalid && $v.kv.$dirty,
            }"
            :message="{ 'Некорректно заполено поле': $v.kv.$error }"
          >
            <!-- <b-input
              placeholder="Комиссионное вознаграждение"
              icon-right="ruble-sign"
              v-model="kv"
              @blur="
                onTouchField('kv');
                removeKeyFromServerValid('kv');
              "
            ></b-input> -->
            <IMaskComponent
              placeholder="Комиссионное вознаграждение"
              inputmode="numeric"
              class="input"
              :class="{
                'is-danger': $v.kv.$error || 'kv' in serverValid,
                'is-success': !$v.kv.$invalid && $v.kv.$dirty,
              }"
              :mask="Number"
              :scale="2"
              :padFractionalZeros="true"
              :normalizeZeros="true"
              :radix="'.'"
              v-model.trim="kv"
              @blur="
                onTouchField('kv');
                removeKeyFromServerValid('kv');
              "
            />
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-two-thirds-desktop is-half-fullhd"
        >
          <b-field
            label="Название страховой"
            :type="{
              'is-danger':
                $v.insuranceName.$error || 'insuranceName' in serverValid,
              'is-success':
                !$v.insuranceName.$invalid && $v.insuranceName.$dirty,
            }"
            :message="{ 'Некорректно заполено поле': $v.insuranceName.$error }"
          >
            <b-input
              placeholder="Название страховой"
              v-model="insuranceName"
              @blur="
                onTouchField('insuranceName');
                removeKeyFromServerValid('insuranceName');
              "
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="button-wrapper">
        <b-button type="is-success" native-type="submit">Создать</b-button>
        <b-button
          type="is-info"
          native-type="button"
          @click.prevent="submit(true)"
          >Создать и выдать</b-button
        >
      </div>
    </form>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { IMaskComponent } from 'vue-imask';
import { MaskedRange } from 'imask';
import {
  minLength,
  maxLength,
  required,
  decimal,
} from 'vuelidate/lib/validators';
import DadataAPI from '@/api/dadata';
import debounce from '@/utils/debounce';
import { onTouchField, removeKeyFromServerValid } from '@/utils/helpers';
import { helpers } from 'vuelidate/lib/validators';
const alphaNum = helpers.regex('alphaNum', /^[а-яА-Я0-9]*$/);
const alphaNumEng = helpers.regex('alphaNum', /^[a-zA-Z0-9]*$/);

const startDateMaskBlocks = {
  D: {
    mask: MaskedRange,
    placeholderChar: 'Д',
    from: 1,
    to: 31,
    maxLength: 2,
  },
  M: {
    mask: MaskedRange,
    placeholderChar: 'М',
    from: 1,
    to: 12,
    maxLength: 2,
  },
  Y: {
    mask: MaskedRange,
    placeholderChar: 'Г',
    from: dayjs().subtract(40, 'years').year(),
    to: dayjs().year(),
    maxLength: 4,
  },
  h: {
    mask: MaskedRange,
    placeholderChar: '00',
    from: 0,
    to: 23,
    maxLength: 2,
  },
  m: {
    mask: MaskedRange,
    placeholderChar: '00',
    from: 0,
    to: 59,
    maxLength: 2,
  },
};

const endDateMaskBlocks = {
  D: {
    mask: MaskedRange,
    placeholderChar: 'Д',
    from: 1,
    to: 31,
    maxLength: 2,
  },
  M: {
    mask: MaskedRange,
    placeholderChar: 'М',
    from: 1,
    to: 12,
    maxLength: 2,
  },
  Y: {
    mask: MaskedRange,
    placeholderChar: 'Г',
    from: dayjs().subtract(40, 'years').year(),
    to: dayjs().add(30, 'years').year(),
    maxLength: 4,
  },
  h: {
    mask: MaskedRange,
    placeholderChar: '00',
    from: 0,
    to: 23,
    maxLength: 2,
  },
  m: {
    mask: MaskedRange,
    placeholderChar: '00',
    from: 0,
    to: 59,
    maxLength: 2,
  },
};

export default {
  name: 'NewPolis',
  data() {
    return {
      isFetching: false,
      markaData: [],
      clientId: this.$route.params.id,
      series: '',
      number: '',
      type: null,
      vin: '',
      carNumber: '',
      marka: '',
      model: '',
      startDate: '',
      endDate: '',
      amount: '',
      kv: '',
      insuranceName: '',
      withoutVin: false,
      startDateMaskBlocks,
      endDateMaskBlocks,
      onTouchField,
      removeKeyFromServerValid,
      serverValid: {},
    };
  },
  async created() {
    this.$store.commit('toggleLoader', true);
    await this.$store.dispatch('getme');
    this.$store.commit('toggleLoader', false);
  },
  computed: {
    noValid() {
      return this.$store.state.polis.noValid;
    },
    showCarFields() {
      return this.type == '1' || this.type == '2';
    },
  },
  watch: {
    noValid(fields) {
      this.serverValid = { ...fields };
    },
  },
  methods: {
    getDadata: debounce(async function (value, field) {
      if (!value.length) {
        this[field + 'Data'] = [];
        return;
      }

      this.isFetching = true;

      let data = await DadataAPI.getCarBrand({
        value,
      });

      this[field + 'Data'] = [];

      if (data.length) {
        data.forEach((item) => this[field + 'Data'].push(Object.freeze(item)));
      }

      this.isFetching = false;
    }, 500),
    submit(needIssue = false) {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const polisData = {
        clientId: this.clientId,
        series: this.series,
        number: this.number,
        type: this.type,
        startDate: this.startDate,
        endDate: this.endDate,
        amount: this.amount,
        kv: this.kv.length ? this.kv : '0.00',
        insuranceName: this.insuranceName,
      };

      if (this.showCarFields) {
        polisData.carNumber = this.carNumber.toUpperCase();
        polisData.marka = this.marka;
        polisData.model = this.model;
      }

      if (!this.withoutVin && this.showCarFields) {
        polisData.vin = this.vin;
      }

      if (needIssue) {
        polisData.issue = 1;
      }

      this.$store.dispatch('polis/newPolis', polisData);
    },
  },
  validations() {
    const defaultRules = {
      type: { required },
      series: {
        minLength: minLength(1),
        maxLength: maxLength(6),
      },
      number: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(12),
      },
      startDate: {
        required,
      },
      endDate: {
        required,
      },
      amount: {
        required,
        decimal,
      },
      kv: {
        decimal,
      },
      insuranceName: {
        required,
        minLength: minLength(2),
      },
    };

    if (this.showCarFields) {
      defaultRules.carNumber = {
        required,
        alphaNum,
        minLength: minLength(8),
        maxLength: maxLength(9),
      };
      defaultRules.marka = {
        required,
      };
      defaultRules.model = {
        required,
      };
      defaultRules.vin = {
        alphaNumEng,
        minLength: minLength(17),
        maxLength: maxLength(17),
      };
    }

    if (!this.withoutVin && this.showCarFields) {
      defaultRules.vin = {
        required,
        alphaNumEng,
        minLength: minLength(17),
        maxLength: maxLength(17),
      };
    }

    return defaultRules;
  },
  components: { IMaskComponent },
};
</script>

<style lang="scss">
.new-polis {
  display: flex;
  flex-direction: column;
  height: 100%;

  form {
    height: 100%;
    display: flex;
    flex-direction: column;

    .field-with-checkbox {
      position: relative;

      .checkbox-vin {
        margin-top: -5px;
        position: absolute;
      }
    }

    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: auto;

      button[type='button'] {
        margin-left: 20px;
      }
    }
  }
}
</style>
